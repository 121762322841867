import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform'; // Remove all top level attributes
// that are null & requesting cleanup
// NOTE: creates side effects

export function deleteNullValues(obj) {
  if (typeof obj !== 'object') return;
  Object.keys(obj).forEach(key => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });
} // Remove all top level attributes
// that are null & requesting cleanup
// NOTE: creates side effects

export function deleteUndefinedValues(obj) {
  if (typeof obj !== 'object') return;
  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
} // Remove all top level attrs
// that are non-truthy

export function deleteNotTruthey(obj) {
  if (typeof obj !== 'object') return;
  Object.keys(obj).forEach(key => {
    if (!obj[key]) {
      delete obj[key];
    }
  });
  return obj;
} // Remove all empty objects from an object
// returning null if object itself is empty

export function deleteEmptyObjects(src) {
  if (!src || typeof src !== 'object') return src;
  const obj = cloneDeep(src);
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
      delete obj[key];
    }
  });
  return Object.keys(obj).length > 0 ? obj : null;
} // Lookup the hash key of a
// reference item within the hash

export function getHashItemKey(obj, target) {
  return Object.keys(obj).find(key => obj[key] === target) || '';
} // Deep difference between two objects
// returns a new object that contains only
// the changes from the base object.

export function getObjectDiff(object, base) {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
}